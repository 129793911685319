<template>
  <div style="max-width: 140px;margin-top:-2px" class="ml-1 mr-3">
    <v-tooltip top>
      <template #activator="{on, attrs}">
        <a href="javascript:void(0);" class="clear-selected-group-disabled" v-bind="attrs" v-on="on">
          <v-icon
              color="red" @click="clearSelectedTest()" title="Stop Selected" style="
          font-size: 30px;
          position: relative;
          bottom: 1px;
          margin-right: 4px !important;
        " disabled>
            mdi-stop
          </v-icon>
          <span
              style="position: relative; top: 2px"
              v-if="$vuetify.breakpoint.mdAndUp">{{ language.componentLabel.titleStopSelected }}</span>
        </a>
      </template>
      This function is temporarily disabled.
    </v-tooltip>
  </div>
</template>
<script>
// import { clearTest } from "@/js/testHelpers.js";
export default {
  methods: {
    clearSelectedTest() {
      //console.log(response);
      let currentTable = this.table;
      for (let [index, value] of Object.entries(this.selectedRows)) {
        this.stopAndUpdateTest(index, value, currentTable, this);
      }
    },

    stopAndUpdateTest(index, value, currentTable, caller) {
      caller.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=testsExecuteApi',
        data: {
          function: 'stopAndUpdateTest',
          n_id: index,
          n_id_key: value.n_id_key,
        },
      })
          .then(function (response) {
            // console.log(response.data.result.table);
            caller.updateTable(response.data.result.table);
            //  let result = response.data.result.json.result;
            // if (result == "true") {
            //   clearRowInTable(index, currentTable, caller);
            // }
          })
          .catch(function (error) {
            console.log(error);
            //handle error
          });
    },
  },
  props: ['selectedRows', 'table'],
};

// function
</script>

<style scoped>
.clear-selected-group-disabled {
  color: var(--v-disabled-base);
  cursor: default;
}
</style>